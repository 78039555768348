import {
  AnimatedContainer,
  Button,
  Expandable,
  Grid,
  H3,
  HStack,
  Icon,
  Modal,
  P1,
  P2,
  SegmentedControl,
  Spacer,
  Stack,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import BrewShareButton from "components/BrewShareButton";
import DescriptionEditor from "components/editor/DescriptionEditor";
import ReplyToEditor from "components/editor/ReplyToEditor";
import api from "dependencies/api";
import usePrevious from "hooks/usePrevious";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { mutate } from "swr";
import urls from "urls";
import createShareURL from "utils/createShareURL";
import createTwitterLink from "utils/createTwitterLink";
import kFormatter from "utils/kFormatter";
import plausible from "utils/plausible";
import { pluralize } from "utils/pluralize";
import stretchStyle from "utils/stretchStyle";
import { keyNewsletterByShareID } from "utils/swrKeys";
import EmbedCodeModal from "./EmbedCodeModal";
import { PillButton } from "./PillButton";
import StyledA from "./StyledA";
import StyledTooltip from "./StyledTooltip";

const PublishBrewButton = ({ newsletter, variant: providedVariant = undefined }) => {
  const config = useConfig();

  const variant =
    typeof providedVariant === "string" ? [providedVariant] : Array.isArray(providedVariant) ? providedVariant : null;

  const breakpointHit = useBreakpoint();

  const [publishModalShown, setPublishModalShown] = useState(false);
  const prevPublishModalShown = usePrevious(publishModalShown);

  const subscribers = kFormatter(newsletter?.subscribers_count ?? 0);

  const { public: newsletterPublic } = newsletter;
  const [isNewsletterPublic, setIsNewsletterPublic] = useState(newsletterPublic);

  // refresh newsletter on modal close
  useEffect(() => {
    const justHidden = prevPublishModalShown && !publishModalShown;

    if (justHidden && newsletter?.share_id) {
      mutate(keyNewsletterByShareID(newsletter.share_id));
    }
  }, [newsletter?.share_id, prevPublishModalShown, publishModalShown]);

  if (!newsletter) return null;

  return (
    <Fragment>
      {!isNewsletterPublic && (
        <StyledTooltip title="Let anyone subscribe to this brew" style={stretchStyle} touchHold={true}>
          <Button
            icon="arrowUpBold"
            color={config.colors.accent2}
            variant={variant ? [...variant, "white"] : "white"}
            onClick={() => {
              setPublishModalShown(true);
              plausible.track("Click on Publish", { public: isNewsletterPublic });
            }}
            stretch
          >
            Publish
          </Button>
        </StyledTooltip>
      )}

      {isNewsletterPublic &&
        (newsletter?.subscribers_count > 0 ? (
          <Button
            variant={variant}
            icon="people"
            color={config.colors.accent2}
            onClick={() => {
              setPublishModalShown(true);
              plausible.track("Click on Publish", { public: isNewsletterPublic });
            }}
            stretch
          >
            {subscribers + " subs"}
          </Button>
        ) : (
          <Button
            variant={variant}
            icon="worldAltBold"
            color={config.colors.accent2}
            onClick={() => setPublishModalShown(true)}
            stretch
          >
            Public
          </Button>
        ))}
      <BrewPublishModal
        isNewsletterPublic={isNewsletterPublic}
        setIsNewsletterPublic={setIsNewsletterPublic}
        newsletter={newsletter}
        show={publishModalShown}
        setShow={setPublishModalShown}
      />
    </Fragment>
  );
};

export const BrewPublishModal = ({ newsletter, isNewsletterPublic, setIsNewsletterPublic, show, setShow }) => {
  const breakpointHit = useBreakpoint();

  return (
    <Modal
      unmountChildren={false}
      overflow="hidden"
      bottomSheet={breakpointHit}
      bottomSheetWidth="500px"
      show={show}
      setShow={setShow}
    >
      <BrewPublishUi
        isNewsletterPublic={isNewsletterPublic}
        setIsNewsletterPublic={setIsNewsletterPublic}
        newsletter={newsletter}
      />
      <HStack mt={5} align="right">
        <Button onClick={() => setShow(false)}>Done</Button>
      </HStack>
    </Modal>
  );
};

export const BrewPublishUi = ({
  newsletter,
  isNewsletterPublic,
  setIsNewsletterPublic,
  showLinkToPageButton,
  noHeader,
}) => {
  const config = useConfig();
  const router = useRouter();

  const [embedModalShown, setEmbedModalShown] = useState(false);

  const hasSubscribers = newsletter?.subscribers_count > 0;

  const shareUrl = createShareURL(newsletter);
  const title = newsletter.title ? newsletter.title : "this digest";
  const tweetText = `Check out ${title}, made with @mailbrew.

Subscribe 👇`;

  const twitterLink = createTwitterLink({ text: tweetText, url: shareUrl });

  async function handlePublicChange() {
    setIsNewsletterPublic(!isNewsletterPublic);
    plausible.track("Change Newsletter Public", { public: !isNewsletterPublic });
    const res = await api.patch(`/newsletters/${newsletter.id}/`, { public: !isNewsletterPublic });
    mutate(keyNewsletterByShareID(res.data.share_id), res.data, false);
  }

  return (
    <Fragment>
      {!noHeader && (
        <H3 mb={1}>
          <Icon size="20px" currentColor name={isNewsletterPublic ? "worldAltBold" : "lock"} />{" "}
          {isNewsletterPublic ? "Public Brew" : "Private Brew"}
        </H3>
      )}
      <Expandable expanded={!isNewsletterPublic}>
        <P1>Make this brew public to let anyone subscribe for free.</P1>
      </Expandable>
      <Spacer size={3} />
      <SegmentedControl
        options={["private", "public"]}
        optionsNames={["Private", "Public"]}
        optionsIcons={["lock", "worldAltBold"]}
        active={isNewsletterPublic ? "public" : "private"}
        onOptionChange={handlePublicChange}
        backdropStyle={{
          ...config.SegmentedControl.backdropStyle,
          background: isNewsletterPublic ? config.colors.accent2 : config.colors.c3,
        }}
      />
      <Spacer size={4} />
      <AnimatedContainer delay={0.05} startAnimatingAfter={200}>
        <Stack align="center" noWrap>
          {showLinkToPageButton &&
            (isNewsletterPublic ? (
              <PillButton onClick={() => router.push(urls.viewNewsletter(newsletter.share_url))} icon="open">
                Public Page
              </PillButton>
            ) : (
              <PillButton onClick={() => router.push(urls.viewNewsletter(newsletter.share_url))} icon="open">
                Private Page
              </PillButton>
            ))}
          {hasSubscribers ? (
            <PillButton icon="people" onClick={() => router.push(urls.manageSubscribers(newsletter.id))}>
              {kFormatter(newsletter.subscribers_count)} {pluralize("subscriber", newsletter.subscribers_count)}
            </PillButton>
          ) : (
            <PillButton icon="people" onClick={() => router.push(urls.manageSubscribers(newsletter.id))}>
              Add Subscribers
            </PillButton>
          )}
          {isNewsletterPublic && (
            <PillButton icon="code" onClick={() => setEmbedModalShown(!embedModalShown)}>
              Embed
            </PillButton>
          )}
        </Stack>
        <P2 mt={2} align="center">
          Subscribers get new issues of this brew&nbsp;via&nbsp;email.
        </P2>
        <Spacer size={6} />
        {isNewsletterPublic && (
          <Grid columns="1fr 1fr">
            <BrewShareButton
              newsletter={newsletter}
              color={config.colors.accent1}
              wrapperStyle={{ width: "100%", flex: 1 }}
            />
            <StyledA icon="twitter" color={config.colors.twitter} width="100%" href={twitterLink} target="_blank">
              Tweet
            </StyledA>
          </Grid>
        )}
        {isNewsletterPublic && (
          <Fragment>
            <P2 mt={5} mb={1.5}>
              Optional short description:
            </P2>
            <DescriptionEditor newsletterID={newsletter.id} />
            <ReplyToEditor newsletterID={newsletter.id} mt={3} />
          </Fragment>
        )}
      </AnimatedContainer>
      <EmbedCodeModal newsletter={newsletter} show={embedModalShown} setShow={setEmbedModalShown} />
    </Fragment>
  );
};

export default PublishBrewButton;
